<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="isInitialLoading">
  <app-loading-state></app-loading-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="!isInitialLoading && farmers.length === 0">
  <app-empty-state [icon]="faSatellite" [text]="'No Farmers added yet'"></app-empty-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem" *ngIf="!isInitialLoading && farmers.length === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div *ngIf="!isInitialLoading && farmers.length > 0">
  <div class="row">
    <app-farmer
      *ngFor="let farmer of farmers | orderBy: 'satelliteName'; trackBy: trackBy"
      [farmer]="farmer"
      class="p-1 col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-8">
    </app-farmer>
  </div>
</div>