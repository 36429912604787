<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="isInitialLoading">
  <app-loading-state></app-loading-state>
</div>
<div *ngIf="!isInitialLoading" class="row d-flex justify-content-center mt-2">
    <div class="card col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 m-2">
      <div class="card-body">
        <h5 class="card-title text-center">Reorder your Dashboard</h5>
        <div class="text-center" style="padding-bottom: 0.5rem">(drag to reorder)</div>
          <div [sortablejs]="user.satelliteOrder">
          <div *ngFor="let sat of satelliteOrder" class="text-center">
            <button type="button" class="btn btn-info mt-1 w-100"><span class="h6">{{sat}}</span></button>
          </div>
          </div>
        <div class="d-flex justify-content-between mt-3">
          <button type="button" class="btn btn-outline-primary w-50 mr-2" (click)="defaultOrder()">Default</button>
          <button type="button" class="btn btn-outline-primary w-50 ml-2" (click)="saveOrder()">Save</button>
        </div>
      </div>
    </div>
    <div class="card col-12 col-md-5 col-lg-4 col-xl-4 col-xxl-5 m-2">
      <div class="card-body">
        <h5 class="card-title text-center">Show/Hide Groups</h5>
        <div class="text-center" style="padding-bottom: 0.5rem">(click to toggle)</div>
        <div *ngFor="let group of visibleGroups | keyvalue" class="d-flex justify-content-between mt-1">
          <span class="h5">{{group.key}}</span>
          <button (click)="changeVisibility(group)" class="btn btn-dark" placement="bottom">
            <fa-icon [icon]="group.value ? faEye : faEyeSlash" [class.hidden]="group.value == false" class="fa toggle-hidden-icon"></fa-icon>
            {{group.value ? 'Visible' : 'Hidden'}}
          </button>
        </div>
        <div class="d-flex justify-content-between mt-3">
          <button type="button" class="btn btn-outline-primary w-50 mr-1" (click)="defaultVisibility()">Default</button>
          <button type="button" class="btn btn-outline-primary w-50 ml-1" (click)="saveVisibility()">Save</button>
        </div>
        </div>
    </div>
</div>