<ng-template #confirmationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title modal-text">{{title}}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-text">
    <p>{{content}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close('confirm')">Ok</button>
  </div>
</ng-template>
