<div class="row justify-content-center" style="margin-top: 4em">
  <div class="col col-6">
    <div class="row justify-content-center" style="font-size: 24px">
      Log in to Chia-Dashboard
    </div>
    <div class="row justify-content-center" style="margin-top: 1em">
      <a href="{{googleAuthUrl}}" style="text-decoration: none;">
        <div class="oauth-provider-button google-button">
          <fa-icon [icon]="faGoogle" class="fa" style="font-size: 18px"></fa-icon>&nbsp;&nbsp;Continue with Google
        </div>
      </a>
    </div>
    <div class="row justify-content-center" style="margin-top: 1em">
      <a href="{{discordAuthUrl}}" style="text-decoration: none;">
        <div class="oauth-provider-button discord-button">
          <fa-icon [icon]="faDiscord" class="fa" style="font-size: 18px; vertical-align: top"></fa-icon>&nbsp;&nbsp;Continue with Discord
        </div>
      </a>
    </div>
    <div class="row justify-content-center" style="margin-top: 1em">
      <a href="{{githubAuthUrl}}" style="text-decoration: none;">
        <div class="oauth-provider-button github-button">
          <fa-icon [icon]="faGithub" class="fa" style="font-size: 18px"></fa-icon>&nbsp;&nbsp;Continue with GitHub
        </div>
      </a>
    </div>
  </div>
</div>

