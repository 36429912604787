<div class="row justify-content-center" style="font-size: 22px">
  Get the Satellite Software
</div>
<div class="row justify-content-center" style="margin-top: 1em">
  <div class="col-11 col-md-6 col-lg-5 col-xl-5 px-1 mb-1">
  <a href="{{binaryDownloadUrl}}" target="_blank" style="text-decoration: none;">
    <div class="install-box download-button">
      <fa-icon [icon]="faDownload" class="fa" style="font-size: 18px"></fa-icon>&nbsp;&nbsp;Download the latest <br>Chia-Dashboard-Satellite
    </div>
  </a>
</div>
<div class="col-11 col-md-6 col-lg-5 col-xl-5 px-1 mb-1">
  <a href="{{binaryDownloadUrlFlax}}" target="_blank" style="text-decoration: none;">
    <div class="install-box download-button">
      <fa-icon [icon]="faDownload" class="fa" style="font-size: 18px"></fa-icon>&nbsp;&nbsp;Download the latest <br>Flax-Dashboard-Satellite
    </div>
  </a>
</div>
<div class="col-11 col-md-6 col-lg-5 col-xl-5 px-1 mb-1">
  <a href="{{binaryDownloadUrlChaingreen}}" target="_blank" style="text-decoration: none;">
    <div class="install-box download-button">
      <fa-icon [icon]="faDownload" class="fa" style="font-size: 18px"></fa-icon>&nbsp;&nbsp;Download the latest <br>Chaingreen-Dashboard-Satellite
    </div>
  </a>
</div>
<div class="col-11 col-md-6 col-lg-5 col-xl-5 px-1 mb-1">
  <a href="{{binaryDownloadUrlSpare}}" target="_blank" style="text-decoration: none;">
    <div class="install-box download-button">
      <fa-icon [icon]="faDownload" class="fa" style="font-size: 18px"></fa-icon>&nbsp;&nbsp;Download the latest <br>Spare-Dashboard-Satellite
    </div>
  </a>
</div>

<div class="row justify-content-center mx-0" style="margin-top: 1em">
  <div class="col-11 col-md-8 col-lg-6 col-xl-5 mx-1 mb-1 install-box">
    <h5>Chia-Dashboard-Satellite</h5>
    Install using npm:<br>
    <code>npm install --global chia-dashboard-satellite</code>
    <span
      class="px-2"
      ngbTooltip="Copy"
      ngxClipboard
      [cbContent]="'npm install --global chia-dashboard-satellite'"
      style="cursor: pointer">
      <fa-icon [icon]="faCopy"></fa-icon>
    </span>
  </div>
  <div class="col-11 col-md-8 col-lg-6 col-xl-5 mx-1 mb-1 install-box">
    <h5>Flax-Dashboard-Satellite</h5>
    Install using npm:<br>
    <code>npm install --global flax-dashboard-satellite</code>
    <span
      class="px-2"
      ngbTooltip="Copy"
      ngxClipboard
      [cbContent]="'npm install --global flax-dashboard-satellite'"
      style="cursor: pointer">
      <fa-icon [icon]="faCopy"></fa-icon>
    </span>
  </div>
  <div class="col-11 col-md-8 col-lg-6 col-xl-5 mx-1 mb-1 install-box">
    <h5>Chaingreen-Dashboard-Satellite</h5>
    Install using npm:<br>
    <code>npm install --global chaingreen-dashboard-satellite</code>
    <span
      class="px-2"
      ngbTooltip="Copy"
      ngxClipboard
      [cbContent]="'npm install --global chaingreen-dashboard-satellite'"
      style="cursor: pointer">
      <fa-icon [icon]="faCopy"></fa-icon>
    </span>
  </div>
  <div class="col-11 col-md-8 col-lg-6 col-xl-5 mx-1 mb-1 install-box">
    <h5>Spare-Dashboard-Satellite</h5>
    Install using npm:<br>
    <code>npm install --global spare-dashboard-satellite</code>
    <span
      class="px-2"
      ngbTooltip="Copy"
      ngxClipboard
      [cbContent]="'npm install --global spare-dashboard-satellite'"
      style="cursor: pointer">
      <fa-icon [icon]="faCopy"></fa-icon>
    </span>
  </div>
</div>
