<div class="card">
  <div class="card-header">
    <h5>Plotter ({{satelliteName}})
      <fa-icon [icon]="collapsedState ? faChevronDown : faChevronUp" class="float-right text-muted h6" (click)="toggleCollapse()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"></fa-icon></h5>
  </div>
  <div class="card-body card-body-with-title" [class.plotter-card]="runningJobsCount > 0">
    <h5 class="card-title">
      Status: <span [class]="colorClassForStatus">{{status}}</span>
      <img *ngIf="satelliteCoin === 'Chia'" src="assets/chia_icon.png" height="20" class="float-right">
      <img *ngIf="satelliteCoin === 'Flax'" src="assets/flax_icon.png" height="20" class="float-right">
      <img *ngIf="satelliteCoin === 'Chaingreen'" src="assets/chaingreen_icon.png" height="20" class="float-right">
      <img *ngIf="satelliteCoin === 'Spare'" src="assets/spare_icon.png" height="20" class="float-right">
    </h5>
    <h6 class="card-subtitle mb-2 text-muted d-flex justify-content-between">
      <span>
        Running: {{runningJobsCount}}<span *ngIf="pendingJobsCount > 0"> / Pending: {{pendingJobsCount}}</span>
      </span>
      <span *ngIf="completedPlotsToday !== undefined">
        <span ngbTooltip="Today">T</span>: {{completedPlotsToday}} / <span ngbTooltip="Yesterday">Y</span>: {{completedPlotsYesterday}}
      </span>
    </h6>
    <div #collapse="ngbCollapse" [ngbCollapse]="collapsedState" class="table-responsive">
      <table class="table table-sm table-dark plot-job-table">
        <thead>
          <tr>
            <th scope="col">Size</th>
            <th scope="col"><span ngbTooltip="Phase">P</span></th>
            <th scope="col">Elapsed</th>
            <th scope="col">Progress</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let job of runningJobs">
            <td>K-{{job.kSize}}</td>
            <td *ngIf="job.phase !== undefined">{{job.phase}}</td>
            <td *ngIf="job.phase === undefined">-</td>
            <td>{{getJobRuntime(job)}}</td>
            <td>{{getJobProgress(job)}}</td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="(tempDrives.length === 0 && destDrives.length === 0) && (drives.length > 0)" class="table table-sm table-dark plot-drive-table">
        <thead>
          <tr>
            <th scope="col">Drive</th>
            <th scope="col">Free</th>
            <th scope="col">% Full</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let drive of drives">
            <td *ngIf="drive.letter.length >= 8"><span ngbTooltip={{drive.letter}}>{{shortenDriveName(drive.letter)}}</span></td>
            <td *ngIf="drive.letter.length < 8">{{shortenDriveName(drive.letter)}}</td>
            <td>{{driveFreeSpace(drive.used, drive.total)}}TiB</td>
            <td><span [class]="driveFullIndicator(drive.percent)">
              {{percentRound(drive.percent)}}%</span></td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="tempDrives.length > 0" class="table table-sm table-dark plot-drive-table">
        <thead>
          <tr>
            <th scope="col">Temp</th>
            <th scope="col">Free</th>
            <th scope="col">% Full</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let drive of tempDrives">
            <td *ngIf="drive.letter.length >= 8"><span ngbTooltip={{drive.letter}}>{{shortenDriveName(drive.letter)}}</span></td>
            <td *ngIf="drive.letter.length < 8">{{shortenDriveName(drive.letter)}}</td>
            <td>{{driveFreeSpace(drive.used, drive.total)}}TiB</td>
            <td><span [class]="driveFullIndicator(drive.percent)">
              {{percentRound(drive.percent)}}%</span></td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="destDrives.length > 0" class="table table-sm table-dark plot-drive-table">
        <thead>
          <tr>
            <th scope="col">Dest</th>
            <th scope="col">Free</th>
            <th scope="col">% Full</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let drive of destDrives">
            <td *ngIf="drive.letter.length >= 8"><span ngbTooltip={{drive.letter}}>{{shortenDriveName(drive.letter)}}</span></td>
            <td *ngIf="drive.letter.length < 8">{{shortenDriveName(drive.letter)}}</td>
            <td>{{driveFreeSpace(drive.used, drive.total)}}TiB</td>
            <td><span [class]="driveFullIndicator(drive.percent)">
              {{percentRound(drive.percent)}}%</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer">
    <small class="text-muted">
      <div  *ngIf="cpu !== undefined">
        <span ngbTooltip="CPU">CPU: {{cpu}}</span> / <span ngbTooltip="RAM">RAM: {{ram}}</span>
        <br />
      </div>
      <span class="dot"
            [attr.green]="lastUpdatedState === 0 ? true : null"
            [attr.orange]="lastUpdatedState === 1 ? true : null"
            [attr.red]="lastUpdatedState === 2 ? true : null">
      </span>
      Last updated {{lastUpdatedBefore}}</small>
  </div>
</div>
