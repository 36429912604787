<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="isInitialLoading">
  <app-loading-state></app-loading-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="!isInitialLoading && harvesters.length === 0">
  <app-empty-state [icon]="faSatellite" [text]="'No Harvesters added yet'"></app-empty-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem" *ngIf="!isInitialLoading && harvesters.length === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div *ngIf="!isInitialLoading && harvesters.length > 0">
  <div class="row">
    <app-harvester
      *ngFor="let harvester of harvesters | orderBy: 'satelliteName'; trackBy: trackBy"
      [harvester]="harvester"
      [bestBlockchainState]="bestBlockchainState" 
      [bestBlockchainStateFlax]="bestBlockchainStateFlax" 
      [bestBlockchainStateChaingreen]="bestBlockchainStateChaingreen" 
      [bestBlockchainStateSpare]="bestBlockchainStateSpare" 
      [selectedDashboard]="selectedDashboard" 
      class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
    </app-harvester>
  </div>
</div>