<div class="card">
  <div class="card-header">
    <h5>Harvester ({{satelliteName}})</h5>
  </div>
  <div class="card-body card-body-with-title">
    <h5 class="card-title">
      Status: <span [class]="colorClassForSyncStatus">{{status}}</span>
      <img *ngIf="satelliteCoin === 'Chia'" src="assets/chia_icon.png" height="20" class="float-right">
      <img *ngIf="satelliteCoin === 'Flax'" src="assets/flax_icon.png" height="20" class="float-right">
      <img *ngIf="satelliteCoin === 'Chaingreen'" src="assets/chaingreen_icon.png" height="20" class="float-right">
      <img *ngIf="satelliteCoin === 'Spare'" src="assets/spare_icon.png" height="20" class="float-right">
    </h5>
    <h6 class="card-subtitle mb-2 text-muted">
      {{plotCount}} Plots
    </h6>
    <div class="d-flex justify-content-between">
      <div class="card-font-size">Capacity</div>
      <div class="card-font-size font-weight-bold">{{capacity}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="card-font-size">Est. time to win</div>
      <div class="card-font-size font-weight-bold">{{estimatedTimeToWinInHours}}</div>
    </div>
  </div>
  <div class="card-footer">
    <small class="text-muted">
      <span class="dot"
            [attr.green]="lastUpdatedState === 0 ? true : null"
            [attr.orange]="lastUpdatedState === 1 ? true : null"
            [attr.red]="lastUpdatedState === 2 ? true : null">
      </span>
      Last updated {{lastUpdatedBefore}}</small>
  </div>
</div>
