<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="isInitialLoading">
  <app-loading-state></app-loading-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 1rem"
  *ngIf="!isInitialLoading && satellites.length === 0">
  <app-empty-state [icon]="faSatellite" [text]="'No Satellites added yet'"></app-empty-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem"
  *ngIf="!isInitialLoading && selectedDashboard === 'Chia' && satellitesChiaCount === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem"
  *ngIf="!isInitialLoading && selectedDashboard === 'Flax' && satellitesFlaxCount === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem"
  *ngIf="!isInitialLoading && selectedDashboard === 'Chaingreen' && satellitesChaingreenCount === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem"
  *ngIf="!isInitialLoading && selectedDashboard === 'Spare' && satellitesSpareCount === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem"
  *ngIf="!isInitialLoading && selectedDashboard === 'All' && satellites.length === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div *ngIf="!isInitialLoading && satellites.length > 0">
  <div class="row" *ngIf="satellitesChiaCount > 0">
    <app-farm-summary-chia *ngIf="selectedDashboard === 'Chia' || selectedDashboard === 'All'" [wallets]="wallets('Chia')" [fullNodes]="fullNodes('Chia')" [harvesters]="harvesters('Chia')" [farmers]="farmers('Chia')"
      [bestBlockchainState]="bestBlockchainState" [rate]="rate" [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard"
      class="p-1 col-12 col-md-6 col-lg-8 col-xl-8 col-xxl-18">
    </app-farm-summary-chia>
    <app-wallet-summary-chia *ngIf="selectedDashboard === 'Chia' || selectedDashboard === 'All'" [wallets]="wallets('Chia')" [bestBlockchainState]="bestBlockchainState" [rate]="rate"
      [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard" class="p-1 col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-6">
    </app-wallet-summary-chia>
  </div>
  <div class="row" *ngIf="satellitesFlaxCount > 0">
    <app-farm-summary-flax *ngIf="selectedDashboard === 'Flax' || selectedDashboard === 'All'" [wallets]="wallets('Flax')" [fullNodes]="fullNodes('Flax')" [harvesters]="harvesters('Flax')" [farmers]="farmers('Flax')"
      [bestBlockchainState]="bestBlockchainStateFlax" [rate]="rate" [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard"
      class="p-1 col-12 col-md-6 col-lg-8 col-xl-8 col-xxl-18">
    </app-farm-summary-flax>
    <app-wallet-summary-flax *ngIf="selectedDashboard === 'Flax' || selectedDashboard === 'All'" [wallets]="wallets('Flax')" [bestBlockchainState]="bestBlockchainStateFlax" [rate]="rate"
      [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard" class="p-1 col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-6">
    </app-wallet-summary-flax>
  </div>
  <div class="row" *ngIf="satellitesChaingreenCount > 0">
    <app-farm-summary-chaingreen *ngIf="selectedDashboard === 'Chaingreen' || selectedDashboard === 'All'" [wallets]="wallets('Chaingreen')" [fullNodes]="fullNodes('Chaingreen')" [harvesters]="harvesters('Chaingreen')" [farmers]="farmers('Chaingreen')"
      [bestBlockchainState]="bestBlockchainStateChaingreen" [rate]="rate" [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard"
      class="p-1 col-12 col-md-6 col-lg-8 col-xl-8 col-xxl-18">
    </app-farm-summary-chaingreen>
    <app-wallet-summary-chaingreen *ngIf="selectedDashboard === 'Chaingreen' || selectedDashboard === 'All'" [wallets]="wallets('Chaingreen')" [bestBlockchainState]="bestBlockchainStateChaingreen" [rate]="rate"
      [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard" class="p-1 col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-6">
    </app-wallet-summary-chaingreen>
  </div>
  <div class="row" *ngIf="satellitesSpareCount > 0">
    <app-farm-summary-spare *ngIf="selectedDashboard === 'Spare' || selectedDashboard === 'All'" [wallets]="wallets('Spare')" [fullNodes]="fullNodes('Spare')" [harvesters]="harvesters('Spare')" [farmers]="farmers('Spare')"
      [bestBlockchainState]="bestBlockchainStateSpare" [rate]="rate" [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard"
      class="p-1 col-12 col-md-6 col-lg-8 col-xl-8 col-xxl-18">
    </app-farm-summary-spare>
    <app-wallet-summary-spare *ngIf="selectedDashboard === 'Spare' || selectedDashboard === 'All'" [wallets]="wallets('Spare')" [bestBlockchainState]="bestBlockchainStateSpare" [rate]="rate"
      [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard" class="p-1 col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-6">
    </app-wallet-summary-spare>
  </div>
  <div class="row" style="display:flex;">
    <ng-container *ngIf="visibleGroups()['Plotters']">
      <app-plotter [style.order]="satelliteOrder().plotter"
        *ngFor="let plotter of plotters | orderBy: 'satelliteName'; trackBy: trackBy" [plotter]="plotter" [selectedDashboard]="selectedDashboard"
        class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
      </app-plotter>
    </ng-container>
    <ng-container *ngIf="visibleGroups()['Harvesters']">
      <app-harvester [style.order]="satelliteOrder().harvester"
        *ngFor="let harvester of harvesters(selectedDashboard) | orderBy: 'satelliteName'; trackBy: trackBy" [harvester]="harvester"
        [bestBlockchainState]="bestBlockchainState" [bestBlockchainStateFlax]="bestBlockchainStateFlax" [bestBlockchainStateChaingreen]="bestBlockchainStateChaingreen" [bestBlockchainStateSpare]="bestBlockchainStateSpare" [selectedDashboard]="selectedDashboard" class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
      </app-harvester>
    </ng-container>
    <ng-container *ngIf="visibleGroups()['Farmers']">
      <app-farmer [style.order]="satelliteOrder().farmer"
        *ngFor="let farmer of farmers(selectedDashboard) | orderBy: 'satelliteName'; trackBy: trackBy" [farmer]="farmer" [selectedDashboard]="selectedDashboard"
        class="p-1 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-8">
      </app-farmer>
    </ng-container>
      <ng-container *ngIf="visibleGroups()['Full Nodes']">
      <app-full-node [style.order]="satelliteOrder().fullnode"
        *ngFor="let fullNode of fullNodes(selectedDashboard) | orderBy: 'satelliteName'; trackBy: trackBy" [fullNode]="fullNode" [selectedDashboard]="selectedDashboard"
        class="p-1 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-6">
      </app-full-node>
    </ng-container>
    <ng-container *ngIf="visibleGroups()['Wallets']">
    <app-wallet [style.order]="satelliteOrder().wallet" *ngFor="let wallet of wallets(selectedDashboard); trackBy: trackBy" [wallet]="wallet"
      [rate]="rate" [selectedCurrency]="selectedCurrency" [selectedDashboard]="selectedDashboard"
      class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
    </app-wallet>
  </ng-container>
    </div>
</div>
