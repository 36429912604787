<div class="card">
  <div class="card-header d-flex justify-content-between">
    <h5 style="margin-right: 1rem">
      <editable (save)="updateName()" (cancel)="cancelNameUpdate()">
        <ng-template viewMode>{{satellite.name}}</ng-template>

        <ng-template editMode>
          <input editableOnEnter editableOnEscape class="form-control" type="text" [formControl]="nameControl" />
        </ng-template>
      </editable>
    </h5>
    <fa-icon
      [icon]="faTrash"
      (click)="onDelete()"
      class="fa delete-button"
      placement="bottom"
      ngbTooltip="Delete this Satellite"
    ></fa-icon>
  </div>
  <div class="card-body">
    <button
      (click)="toggleHidden()"
      class="btn btn-dark"
      placement="bottom"
      ngbTooltip="The Satellite is currently {{satellite.hidden ? 'hidden' : 'shown'}} in the dashboard"
    >
      <fa-icon
        [icon]="satellite.hidden ? faEyeSlash : faEye"
        [class.hidden]="satellite.hidden"
        class="fa toggle-hidden-icon"
      ></fa-icon>
      {{satellite.hidden ? 'Hidden' : 'Visible'}}
    </button>
    <div style="margin-top: 5px">
      <button (click)="open(keyModal)" class="btn btn-dark">Show API Key</button>
    </div>
  </div>
  <div class="card-footer">
    <small class="text-muted d-flex justify-content-between">
      <span>Last updated {{lastUpdatedBefore}}</span>
      <span *ngIf="satellite.version">&nbsp;| v{{satellite.version}}</span>
    </small>
  </div>
</div>

<ng-template #keyModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{satellite.name}}: API Key</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <input type="text" class="form-control" [value]="satellite.apiKey" readonly>
      <div class="input-group-append">
        <div class="input-group-text"
               ngbTooltip="Copy"
               ngxClipboard
               [cbContent]="satellite.apiKey"
               style="cursor: pointer">
          <fa-icon [icon]="faCopy"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Close</button>
  </div>
</ng-template>