<nav class="navbar navbar-expand-xl navbar-dark sticky-navbar">
  <a class="navbar-brand" href="/">
    <img *ngIf="selectedDashboard === 'Chia' || selectedDashboard === 'All'" src="assets/chia_logo.svg" height="32">
      <img *ngIf="selectedDashboard === 'Flax'" src="assets/flax_logo.png" height="32" class>
      <img *ngIf="selectedDashboard === 'Chaingreen'" src="assets/chaingreen_logo.png" height="32" class>
      <img *ngIf="selectedDashboard === 'Spare'" src="assets/spare_logo.png" height="32">
    <span style="margin-left: 10px; vertical-align: bottom;">Dashboard</span>
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleMenuCollapse()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" *ngIf="user">
        <a class="nav-link px-1">
          <button type="button" class="btn btn-outline-success" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onTabButtonClick()">
            Dashboard
          </button>
        </a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link px-1">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-success" id="plotterDropDown" ngbDropdownToggle>
              Plotters
            </button>
            <div ngbDropdownMenu aria-labelledby="plotterDropDown">
              <button ngbDropdownItem routerLink="/plotters/summary" routerLinkActive="active" >Summary</button>
              <!-- <button ngbDropdownItem routerLink="/plotters/details">Details</button> -->
              <button ngbDropdownItem routerLink="/plotters/drives"routerLinkActive="active" >Drives</button>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link px-1">
          <button type="button" class="btn btn-outline-success" routerLink="/harvesters/summary" routerLinkActive="active" (click)="onTabButtonClick()">
            Harvesters
          </button>
        </a>
      </li>
      <li class="nav-item" *ngIf="user">
        <a class="nav-link px-1">
          <button type="button" class="btn btn-outline-success" routerLink="/farmers/summary" routerLinkActive="active" (click)="onTabButtonClick()">
            Farmers
          </button>
        </a>
      </li>
      <li class="nav-item"  *ngIf="user">
        <a class="nav-link px-1">
          <button type="button" class="btn btn-outline-info" (click)="openAddSatelliteModal()">
            + Add
          </button>
        </a>
      </li>
    </ul>
    <div *ngIf="isAuthenticated">
      <div class="coin-select-wrapper px-1" *ngIf="(isAuthenticated || hasShareKey)">
        <select class="custom-select currency-select" [ngModel]="selectedDashboard" (ngModelChange)="setSelectedDashboard($event)">
          <option *ngFor="let type of dashboardTypes" [ngValue]="type" class="currency-select-option">{{type}}</option>
        </select>
      </div>

      <div ngbDropdown class="d-inline-block px-1">
        <button class="btn btn-outline-info" id="userDropdown" ngbDropdownToggle>
          <span *ngIf="user && user.avatarUrl">
            <img src="{{user.avatarUrl}}" width="20" height="20" style="border-radius: 100%">
            <span *ngIf="displayName() != null" style="margin-left: 8px">{{displayName()}}</span>
          </span>
        </button>
        <div ngbDropdownMenu aria-labelledby="userDropdown">
          <div class="ngbDropdownItem currency-select-wrapper px-3 pb-2" *ngIf="(isAuthenticated || hasShareKey) && currencies.length > 0">
            <select class="custom-select currency-select" [ngModel]="selectedCurrency" (ngModelChange)="setSelectedCurrency($event)">
              <option *ngFor="let currency of currencies" [ngValue]="currency" class="currency-select-option">{{ currency.toUpperCase() }}</option>
            </select>
          </div>
          <button ngbDropdownItem routerLink="/satellites" routerLinkActive="active" (click)="onTabButtonClick()">
            Satellites
          </button>
          <button ngbDropdownItem routerLink="downloads" routerLinkActive="active">
            Downloads
          </button> 
          <button ngbDropdownItem routerLink="account/dashboardsettings" routerLinkActive="active">
            Account Settings
          </button>
          <button ngbDropdownItem routerLink="/account/sharedashboard" routerLinkActive="active">
            Share Dashboard
          </button>
          <button ngbDropdownItem (click)="logout()">
            Logout
          </button>
        </div>
      </div>
      <span class="px-1">
        <a class="btn btn-light px-1" href="https://www.paypal.com/donate/?business=VGRZESXG5Z4BQ&currency_code=USD" role="button" target="_blank" (click)="isMenuCollapsed = true">
          Donate <fa-icon [icon]="faHeart" class="fa" style="color: rgb(219, 97, 162)"></fa-icon>
        </a>
      </span>
    </div>

    <div *ngIf="!isAuthenticated" style="padding-right: 8px; padding-top: 8px; padding-bottom: 8px">
      <button type="button" class="btn btn-dark" routerLink="/login" routerLinkActive="active" (click)="isMenuCollapsed = true">
        Login
      </button>
    </div>
  </div>
</nav>
<app-add-new-satellite-modal></app-add-new-satellite-modal>

