<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="isInitialLoading">
  <app-loading-state></app-loading-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 1rem" *ngIf="!isInitialLoading && satellites.length === 0">
  <app-empty-state [icon]="faSatellite" [text]="'No Satellites added yet'"></app-empty-state>
</div>
<div class="d-flex justify-content-center" style="padding-top: 2rem" *ngIf="!isInitialLoading && satellites.length === 0 && !shareKey">
  <app-download-links></app-download-links>
</div>
<div *ngIf="!isInitialLoading && satellites.length > 0">
  <!-- <div class="row">
    <app-farm-summary
      [wallets]="wallets"
      [fullNodes]="fullNodes"
      [harvesters]="harvesters"
      [farmers]="farmers"
      [bestBlockchainState]="bestBlockchainState"
      [rate]="rate"
      [selectedCurrency]="selectedCurrency"
      class="p-1 col-12 col-md-6 col-lg-8 col-xl-8 col-xxl-18">
    </app-farm-summary>
    <app-wallet-summary
      [wallets]="wallets"
      [bestBlockchainState]="bestBlockchainState"
      [rate]="rate"
      [selectedCurrency]="selectedCurrency"
      class="p-1 col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-6">
    </app-wallet-summary>
  </div> -->
  <div class="row">
    <app-hpool-miner
      *ngFor="let hpoolMiner of hpoolMiners | orderBy: 'satelliteName'; trackBy: trackBy"
      [hpoolMiner]="hpoolMiner"
      class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
    </app-hpool-miner>
    <!-- <app-plotter
      *ngFor="let plotter of plotters | orderBy: 'satelliteName'; trackBy: trackBy"
      [plotter]="plotter"
      class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
    </app-plotter>
    <app-harvester
      *ngFor="let harvester of harvesters; trackBy: trackBy"
      [harvester]="harvester"
      [bestBlockchainState]="bestBlockchainState"
      class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
    </app-harvester>
    <app-farmer
      *ngFor="let farmer of farmers; trackBy: trackBy"
      [farmer]="farmer"
      class="p-1 col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-8">
    </app-farmer>
    <app-full-node
      *ngFor="let fullNode of fullNodes; trackBy: trackBy"
      [fullNode]="fullNode"
      class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
    </app-full-node>
    <app-wallet
      *ngFor="let wallet of wallets; trackBy: trackBy"
      [wallet]="wallet"
      [rate]="rate"
      [selectedCurrency]="selectedCurrency"
      class="p-1 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-4">
    </app-wallet> -->
  </div>
</div>